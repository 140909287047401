// src/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { Form, Input, Button, Typography, Alert, Layout, notification } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import logo from '../logo.png'; // Replace with the path to your logo

const { Title } = Typography;
const { Content } = Layout;

const Login = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async (values) => {
    const { email, password } = values;
    try {
      const response = await axios.post('https://yogigraphics.in/backend/login.php', {
        email,
        password,
      });
      if (response.data.success) {
        const { token, expiresIn } = response.data;
        const expirationTime = new Date().getTime() + expiresIn * 1000; // expiresIn is in seconds
        localStorage.setItem('authToken', token);
        localStorage.setItem('sessionExpiry', expirationTime);
        notification.success({
          message: 'Login Successful',
          description: 'You have successfully logged in!',
          placement: 'topRight',
        });
        // Redirect to dashboard
        navigate('/dashboard/home');
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      notification.error({
        message: 'Login Failed',
        description: 'An error occurred. Please try again.',
        placement: 'topRight',
      });
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img src={logo} alt="Logo" style={{ width: 150, marginBottom: 20 }} />
        <Title level={2}>Login</Title>
        <Form
          name="login"
          onFinish={handleSubmit}
          style={{ maxWidth: 400, width: '100%' }}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Login
            </Button>
          </Form.Item>
          {error && (
            <Form.Item>
              <Alert message={error} type="error" showIcon />
            </Form.Item>
          )}
        </Form>
      </Content>
    </Layout>
  );
};

export default Login;
