// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/pages/login/login';
import Dashboard from './components/pages/dashboard/dashboard';
import Home from './components/pages/home/home'; // Ensure these components are created
import Customer from './components/pages/customer/customer';
import Product from './components/pages/product/product';
import InvoiceForm from './components/pages/invoice/invoice';
import SessionManager from './utils/sessionmanager';
import PurchaseInvoiceForm from './components/pages/invoice/purchase';
import AddSupplier from './components/pages/purchase/supplier';
import PurchaseInvoiceHistory from './components/pages/purchase/purchase_history';
import AdvancePaymentForm from './components/pages/salary/salary';

const App = () => (
  <Router>
    <SessionManager>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard/home" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route path="home" element={<Home />} />
          <Route path="invoice" element={<InvoiceForm />} />
          <Route path="customer" element={<Customer />} />
          <Route path="product" element={<Product />} />
          <Route path="psh" element={<PurchaseInvoiceForm />} />
          <Route path="addsupplier" element={<AddSupplier />} />
          <Route path="purchaseinvoicehistory" element={<PurchaseInvoiceHistory />} />
          <Route path="salary" element={<AdvancePaymentForm />} />
        </Route>
      </Routes>
    </SessionManager>
  </Router>
);

export default App;
