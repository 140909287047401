import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Spin, Alert, Button, Typography, Input, Modal, Form, InputNumber, DatePicker, notification, Popconfirm } from 'antd';
import Papa from 'papaparse';
import moment from 'moment';
import { saveAs } from 'file-saver';
import logo from '../logo.png';
import qrCodeUrl from './gpay.jpeg';

const { Column } = Table;
const { Title } = Typography;

const PurchaseInvoiceHistory = () => {
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [editingInvoice, setEditingInvoice] = useState(null);
  const [editingProduct, setEditingProduct] = useState(null);
  const [form] = Form.useForm();
  const [productForm] = Form.useForm();

  const totalAmount = filteredInvoices.reduce((sum, invoice) => sum + parseFloat(invoice.total_amount || 0), 0);
  const totalProducts = filteredInvoices.reduce((sum, invoice) => 
    sum + (invoice.products ? invoice.products.length : 0), 0);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get('https://yogigraphics.in/backend/add_purchase_invoice.php');
        setInvoices(response.data);
        setFilteredInvoices(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoices();
  }, []);

  useEffect(() => {
    const filtered = invoices.filter(invoice =>
      invoice.supplier.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredInvoices(filtered);
  }, [searchQuery, invoices]);

  const handleEdit = (invoice) => {
    setEditingInvoice(invoice);
    form.setFieldsValue({
      invoice_number: invoice.invoice_number,
      supplier: invoice.supplier,
      address: invoice.address,
      number: invoice.number,
      invoice_date: moment(invoice.invoice_date),
      total_amount: invoice.total_amount,
      advance_payment: invoice.advance_payment || 0,
      remaining_payment: invoice.remaining_payment || 0
    });
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.post('https://yogigraphics.in/backend/delete_invoice.php', { id });
      if (response.data.success) {
        notification.success({ message: 'Success', description: 'Invoice deleted successfully' });
        setInvoices(invoices.filter(invoice => invoice.id !== id));
        setFilteredInvoices(filteredInvoices.filter(invoice => invoice.id !== id));
      } else {
        notification.error({ message: 'Error', description: response.data.message });
      }
    } catch (error) {
      notification.error({ message: 'Error', description: 'Failed to delete invoice' });
    }
  };

  const handleUpdate = async (values) => {
    try {
      const advancePayment = parseFloat(values.advance_payment) || 0;
      const totalAmount = parseFloat(values.total_amount) || 0;
      const remainingPayment = totalAmount - advancePayment;

      const response = await axios.post('https://yogigraphics.in/backend/update_purchase_invoice.php', {
        ...values,
        remaining_payment: remainingPayment,
        invoiceId: editingInvoice.id
      });

      if (response.data.success) {
        notification.success({ message: 'Success', description: 'Invoice updated successfully' });
        setEditingInvoice(null);
        form.resetFields();
        const updatedInvoices = invoices.map(invoice => 
          invoice.id === editingInvoice.id ? { ...invoice, ...values, remaining_payment: remainingPayment } : invoice
        );
        setInvoices(updatedInvoices);
        setFilteredInvoices(updatedInvoices);
      } else {
        notification.error({ message: 'Error', description: response.data.message });
      }
    } catch (error) {
      notification.error({ message: 'Error', description: 'Failed to update invoice' });
    }
  };

  const handleProductUpdate = async (values) => {
    try {
      const response = await axios.post('https://yogigraphics.in/backend/update_purchase_stock.php', {
        ...values,
        invoiceId: editingInvoice.id
      });

      if (response.data.success) {
        notification.success({ message: 'Success', description: 'Product updated successfully' });
        setEditingProduct(null);
        productForm.resetFields();
        const updatedProducts = editingInvoice.products.map(product => 
          product.id === editingProduct.id ? { ...product, ...values } : product
        );
        const updatedInvoices = invoices.map(invoice => 
          invoice.id === editingInvoice.id ? { ...invoice, products: updatedProducts } : invoice
        );
        setInvoices(updatedInvoices);
        setFilteredInvoices(updatedInvoices);
        setEditingInvoice({ ...editingInvoice, products: updatedProducts });
      } else {
        notification.error({ message: 'Error', description: response.data.message });
      }
    } catch (error) {
      notification.error({ message: 'Error', description: 'Failed to update product' });
    }
  };

  const downloadCSV = () => {
    const csvData = filteredInvoices.map(invoice => ({
      'Invoice Number': invoice.invoice_number,
      'Supplier': invoice.supplier,
      'Address': invoice.address,
      'Number': invoice.number,
      'Invoice Date': moment(invoice.invoice_date).format('YYYY-MM-DD'),
      'Total Amount': `₹${parseFloat(invoice.total_amount || 0).toFixed(2)}`,
      'Advance Payment': `₹${parseFloat(invoice.advance_payment || 0).toFixed(2)}`,
      'Remaining Payment': `₹${parseFloat(invoice.remaining_payment || 0).toFixed(2)}`
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'invoices.csv');
  };

  const printInvoice = () => {
    if (!editingInvoice) return;

    const advancePayment = parseFloat(editingInvoice.advance_payment) || 0;
    const totalAmount = parseFloat(editingInvoice.total_amount) || 0;
    const remainingPayment = totalAmount - advancePayment;
    const invoiceHtml = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Invoice</title>
          <style>
              @page {
                  size: A4;
                  margin: 20mm;
              }
              body {
                  font-family: Arial, sans-serif;
                  color: #333;
                  margin: 0;
                  padding: 0;
                  box-sizing: border-box;
                  background-color: #f9f9f9;
              }
              .container {
                  width: 100%;
                  max-width: 800px;
                  margin: 0 auto;
                  padding: 20px;
                  border: 1px solid #ccc;
                  background-color: #fff;
                  border-radius: 8px;
                  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                  position: relative;
              }
              .header {
                  text-align: center;
                  margin-bottom: 20px;
                  border-bottom: 2px solid #333;
                  padding-bottom: 10px;
              }
              .header img {
                  max-width: 150px;
              }
              .header h1 {
                  margin: 0;
                  font-size: 26px;
                  text-transform: uppercase;
                  color: #333;
              }
              .company-details, .invoice-details {
                  margin-bottom: 20px;
                  font-size: 14px;
              }
              .company-details p, .invoice-details p {
                  margin: 5px 0;
              }
              .company-details {
                  border-bottom: 1px solid #ddd;
                  padding-bottom: 10px;
              }
              .invoice-details {
                  border-bottom: 1px solid #ddd;
                  padding-bottom: 10px;
              }
              .items-table {
                  width: 100%;
                  border-collapse: collapse;
                  margin-bottom: 20px;
                  font-size: 12px;
              }
              .items-table th, .items-table td {
                  padding: 10px;
                  text-align: left;
                  border: 1px solid #ddd;
              }
              .items-table th {
                  background-color: #f4f4f4;
                  font-weight: bold;
              }
              .total-row {
                  font-weight: bold;
                  text-align: right;
                  border-top: 2px solid #333;
              }
              .footer {
                  font-size: 12px;
                  text-align: center;
                  margin-top: 20px;
                  padding-top: 10px;
                  border-top: 1px solid #ddd;
              }
              .footer p {
                  margin: 5px 0;
                  color: #666;
              }
              .amount-words {
                  font-style: italic;
                  font-size: 12px;
                  margin-top: 10px;
                  text-align: left;
              }
              .signatory {
                  margin-top: 40px;
                  text-align: right;
                  font-size: 12px;
              }
              .signatory p {
                  margin: 5px 0;
              }
          </style>
      </head>
      <body>
          <div class="container">
              <div class="header">
                  <img src="${logo}" alt="Logo">
                  <h1>Invoice</h1>
              </div>
              <div class="company-details">
                  <p><strong>Supplier:</strong> ${editingInvoice.supplier}</p>
                  <p><strong>Address:</strong> ${editingInvoice.address}</p>
                  <p><strong>Contact Number:</strong> ${editingInvoice.number}</p>
              </div>
              <div class="invoice-details">
                  <p><strong>Invoice Number:</strong> ${editingInvoice.invoice_number}</p>
                  <p><strong>Invoice Date:</strong> ${moment(editingInvoice.invoice_date).format('YYYY-MM-DD')}</p>
              </div>
              <table class="items-table">
                  <thead>
                      <tr>
                          <th>Sl No.</th>
                          <th>Description of Goods</th>
                          <th>Quantity</th>
                          <th>Rate</th>
                          <th>Amount</th>
                      </tr>
                  </thead>
                  <tbody>
                      ${editingInvoice.products.map((product, index) => `
                          <tr>
                              <td>${index + 1}</td>
                              <td>${product.product_name}</td>
                              <td>${product.quantity}</td>
                              <td>₹${parseFloat(product.price).toFixed(2)}</td>
                              <td>₹${(parseFloat(product.quantity) * parseFloat(product.price)).toFixed(2)}</td>
                          </tr>
                      `).join('')}
                      <tr class="total-row">
                          <td colspan="4">Total Amount</td>
                          <td>₹${totalAmount.toFixed(2)}</td>
                      </tr>
                      <tr>
                          <td colspan="4">Advance Payment</td>
                          <td>₹${advancePayment.toFixed(2)}</td>
                      </tr>
                      <tr>
                          <td colspan="4">Remaining Payment</td>
                          <td>₹${remainingPayment.toFixed(2)}</td>
                      </tr>
                  </tbody>
              </table>
              <div class="amount-words">
                  <p>Amount in Words: ${convertNumberToWords(totalAmount)} Only</p>
              </div>
              <div class="footer">
                  <p>Thank you for your business!</p>
                  <p>For any queries, contact us at [Contact Details]</p>
              </div>
              <div class="signatory">
                  <p>Authorized Signature</p>
              </div>
          </div>
      </body>
      </html>
    `;

    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.open();
    printWindow.document.write(invoiceHtml);
    printWindow.document.close();
    printWindow.print();
  };

  const convertNumberToWords = (num) => {
    const a = ['','One','Two','Three','Four','Five','Six','Seven','Eight','Nine','Ten',
               'Eleven','Twelve','Thirteen','Fourteen','Fifteen','Sixteen','Seventeen','Eighteen','Nineteen'];
    const b = ['','Twenty','Thirty','Forty','Fifty','Sixty','Seventy','Eighty','Ninety'];
    const amount = (num < 20) ? a[num] : (num < 100) ? b[Math.floor(num / 10) - 2] + ((num % 10 > 0) ? ' ' + a[num % 10] : '') : '';
    return amount + (num ? ' Rupees' : '');
  };

  return (
    <>
      <div style={{ margin: 20 }}>
        <Input.Search
          placeholder="Search by Supplier"
          onSearch={value => setSearchQuery(value)}
          style={{ marginBottom: 20, width: 300 }}
        />
        <Button type="primary" onClick={downloadCSV}>Download CSV</Button>
        <Table
          dataSource={filteredInvoices}
          rowKey="id"
          style={{ marginTop: 20 }}
          loading={loading}
          pagination={false}
        >
          <Column title="Invoice Number" dataIndex="invoice_number" key="invoice_number" />
          <Column title="Supplier" dataIndex="supplier" key="supplier" />
          <Column title="Invoice Date" dataIndex="invoice_date" key="invoice_date" render={date => moment(date).format('YYYY-MM-DD')} />
          <Column title="Total Amount" dataIndex="total_amount" key="total_amount" render={amount => `₹${parseFloat(amount || 0).toFixed(2)}`} />
          <Column title="Advance Payment" dataIndex="advance_payment" key="advance_payment" render={amount => `₹${parseFloat(amount || 0).toFixed(2)}`} />
          <Column title="Remaining Payment" dataIndex="remaining_payment" key="remaining_payment" render={amount => `₹${parseFloat(amount || 0).toFixed(2)}`} />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <div>
                <Button type="link" onClick={() => handleEdit(record)}>Edit</Button>
                <Popconfirm
                  title="Are you sure to delete this invoice?"
                  onConfirm={() => handleDelete(record.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link">Delete</Button>
                </Popconfirm>
              </div>
            )}
          />
        </Table>
        <Modal
          title="Edit Invoice"
          visible={!!editingInvoice}
          onCancel={() => setEditingInvoice(null)}
          footer={null}
          width={800}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleUpdate}
            initialValues={{
              invoice_number: editingInvoice?.invoice_number,
              supplier: editingInvoice?.supplier,
              address: editingInvoice?.address,
              number: editingInvoice?.number,
              invoice_date: moment(editingInvoice?.invoice_date),
              total_amount: editingInvoice?.total_amount,
              advance_payment: editingInvoice?.advance_payment || 0,
              remaining_payment: editingInvoice?.remaining_payment || 0
            }}
          >
            <Form.Item name="invoice_number" label="Invoice Number" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="supplier" label="Supplier" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="address" label="Address">
              <Input />
            </Form.Item>
            <Form.Item name="number" label="Number">
              <Input />
            </Form.Item>
            <Form.Item name="invoice_date" label="Invoice Date" rules={[{ required: true }]}>
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
            <Form.Item name="total_amount" label="Total Amount">
              <InputNumber min={0} step={0.01} />
            </Form.Item>
            <Form.Item name="advance_payment" label="Advance Payment">
              <InputNumber min={0} step={0.01} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">Update</Button>
              <Button onClick={() => setEditingInvoice(null)} style={{ marginLeft: 8 }}>Cancel</Button>
              <Button onClick={printInvoice} style={{ marginLeft: 8 }}>Print</Button>
            </Form.Item>
          </Form>
          
          {/* Products Table */}
          {editingInvoice?.products && editingInvoice.products.length > 0 && (
            <Table
              dataSource={editingInvoice.products}
              pagination={false}
              rowKey="id"
              style={{ marginTop: 16 }}
            >
              <Column title="Sl No." render={(text, record, index) => index + 1} />
              <Column title="Description of Goods" dataIndex="product_name" key="product_name" />
              <Column title="Quantity" dataIndex="quantity" key="quantity" />
              <Column title="Rate" dataIndex="price" key="price" render={price => `₹${parseFloat(price || 0).toFixed(2)}`} />
              <Column
                title="Amount"
                render={(text, record) => {
                  const price = parseFloat(record.price) || 0;
                  const quantity = parseInt(record.quantity, 10) || 0;
                  const total = (quantity * price).toFixed(2);
                  return `₹${total}`;
                }}
              />
              <Column
                title="Action"
                key="action"
                render={(text, product) => (
                  <Button type="link" onClick={() => {
                    setEditingProduct(product);
                    productForm.setFieldsValue(product);
                  }}>
                    Edit
                  </Button>
                )}
              />
            </Table>
          )}
        </Modal>

        <Modal
          title="Edit Product"
          visible={!!editingProduct}
          onCancel={() => setEditingProduct(null)}
          footer={null}
          width={600}
        >
          <Form
            form={productForm}
            layout="vertical"
            onFinish={handleProductUpdate}
            initialValues={editingProduct}
          >
            <Form.Item name="product_name" label="Product Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="quantity" label="Quantity" rules={[{ required: true }]}>
              <InputNumber min={1} step={1} />
            </Form.Item>
            <Form.Item name="price" label="Rate" rules={[{ required: true }]}>
              <InputNumber min={0} step={0.01} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">Update Product</Button>
              <Button onClick={() => setEditingProduct(null)} style={{ marginLeft: 8 }}>Cancel</Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default PurchaseInvoiceHistory;
